.middle-label {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    text-align: center;
    font: 600 1.4em Lato;

    .leaderboard-icon-container {
        padding-right: 7px;
        display: flex;

        .leaderboard-icon {
            width: 25px;
        }
    }

    .livestreaming-achievement-badge-container {
        display: flex;
        align-items: center;
        gap: 3px;

        .livestreaming-achievement-badge {
            height: 13px;
        }
    }

    .username {
        // max-width: calc(100% - 40px - 20px - 66px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font: 500 0.8em Lato;
    }

    .premium-mini-badge-container {
        display: flex;
        align-items: center;

        .premium-mini-badge {
            height: 13px;
        }
    }

    .verified-profile-container {
        display: flex;
        align-items: center;

        .verified-profile {
            height: 20px;
        }
    }

    .admin-badge-container {
        height: 14px;
        width: 50px;
        padding: 3px 8px 3px 8px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 25px;
        background-color: rgba(205, 79, 79, .78);
        color: white;

        .person-icon {
            font-size: 13px;
        }

        .admin-label {
            display: flex;
            align-items: center;
            font: 500 0.7em Lato;
        }
    }

    .verified-caller-container {
        display: flex;
        align-items: center;

        .verified-caller {
            height: 20px;
        }
    }
}