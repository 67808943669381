@import "../../global-style.scss";

#login-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .tab-container {

            .login-tabs {
                padding-bottom: 20px;
            }
        }

        .sugarbook-logo-container {
            padding-bottom: 50px;
            text-align: center;

            .sugarbook-logo {
                width: 70%;
            }
        }

        .email-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            .email-form-field {
                width: 100%;
            }
        }

        .phone-container {
            display: flex;
            gap: 10px;

            .country-form-field-container {
                height: 50px;
                width: 30%;
                padding-bottom: 30px;
                margin-left: auto;
                margin-right: auto;

                .country-form-field {
                    width: 100%;
                }
            }

            .phone-form-field-container {
                height: 50px;
                width: 70%;
                padding-bottom: 30px;
                margin-left: auto;
                margin-right: auto;

                .phone-form-field {
                    width: 100%;
                }
            }
        }

        .password-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 20px;
            margin-left: auto;
            margin-right: auto;

            .password-form-field {
                width: 100%;

                .show-password,
                .hide-password {
                    color: $mui-icon-default-color;
                }
            }
        }

        .forgot-password-button-container {
            margin-bottom: 20px;
            display: flex;
            justify-content: flex-end;

            .forgot-password-button {
                cursor: pointer;
                color: $sugarbook-grey-text-color;
            }
        }

        .recaptcha-container {
            padding: 15px 0;
            display: flex;
            justify-content: center;
        }

        .button-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .login-button {
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid $sugarbook-main-color;
                border-radius: 25px;
                text-transform: none;
                font: 500 1.1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
                transition: all 0.5s;

                .button-spinner {
                    color: white;
                }
            }

            .join-button-container {

                .join-button {
                    height: 45px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid $sugarbook-main-text-color;
                    border-radius: 25px;
                    cursor: pointer;
                    text-decoration: none;
                    font: 500 1.1em Lato;
                    color: $sugarbook-main-text-color;
                }
            }

            .create-account-label {
                cursor: pointer;
                text-align: center;
                color: $sugarbook-grey-text-color;
            }
        }
    }

    .version-container {
        width: 100%;
        padding-bottom: 30px;
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .app-icon {
            width: 50px;
        }

        .version {
            font: 500 0.7em Lato;
            color: $sugarbook-grey-text-color;
        }
    }

    // Utility CSS
    .disabled-button {
        border: 2px solid transparent !important;
        cursor: not-allowed !important;
        background-color: $mui-disabled-button-background-color !important;
        color: $mui-disabled-button-color !important;
    }

    .inactive-button {
        background-color: rgba(0, 0, 0, .12) !important;
        border-color: transparent !important;
        color: rgba(0, 0, 0, .26) !important;
    }

    .active-button {
        background-color: rgb(113, 13, 13) !important;
        border-color: rgb(113, 13, 13) !important;
        color: white !important;
    }
}