@import "../../global-style.scss";

#body-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;   
}

.flex {
    flex-direction: row !important;
}

.desktop-background-color {
    background-color: $desktop-background-color;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.mobile-default-background-color {
    background-color: $desktop-background-color;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.mobile-white-background-color {
    background-color: white;
}

.mobile-christmas-background {
    background: url("../../assets/background/shared/christmas-mobile-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 66%;
}

.christmas-background {
    background: url("../../assets/background/shared/christmas-desktop-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.anniversary-background {
    background: url("../../assets/background/shared/anniversary-bg-v1.1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

// Responsive Design
@media (min-width: 720px) {
    #body-container {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}