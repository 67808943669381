#app-bar {
    background-color: transparent;

    .app-bar-toolbar {
        height: 80px;
        margin: 20px;
        // background-color: white;

        .app-bar-content-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .app-bar-breadcrumbs {
                display: flex;
                gap: 5px;

                .ancestor {
                    font: 500 0.9em Lato;
                }

                .current {
                    font: 600 0.9em Lato;
                }
            }

            .current-page-label {
                text-transform: capitalize;
                font: 600 1.2em Lato;
            }
        }
    }
}