@import "../../../../../global-style.scss";

#gift-animation-test-container {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .gift-animation-label {
        font: 600 1.5em lato;
    }

    .gift-animation-preview {
        display: flex;
        justify-content: center;

        .gift-animation-preview-center {
            max-width: 300px;
            position: relative;
            border: 1px solid black;
        }

        #test-gift-animation-video-player {
            width: 100%;
        }

        .gift-animation-width {
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
        }

        .gift-animation-height {
            position: absolute;
            left: -45px;
            top: 50%;
            transform: rotate(-90deg) translateY(50%);
        }
    }

    .video-source-file-supported-container {}

    .gift-animation-note {
        font: 500 0.9em Lato;
        color: $sugarbook-grey-text-color;
    }

    .upload-gift-animation-container {
        text-align: center;
    }

    .gift-animation-preview-controls-container {
        display: flex;
        justify-content: space-evenly;

        .play-gift-animation-button,
        .pause-gift-animation-button {
            cursor: pointer;
        }
    }

    .test-animation-button {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        cursor: pointer;
        font: 500 1em Lato;
        color: white;
        background-color: $sugarbook-main-color;
        transition: all 0.5s;
    }

    .disabled-button {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        cursor: not-allowed;
        font: 500 1em Lato;
        color: $sugarbook-grey-text-color;
        background-color: $mui-disabled-button-background-color;
        transition: all 0.5s
    }
}