#coins-package-designer-global-settings-subcomponent {
    padding: 20px;
    border-radius: 15px;
    background-color: white;

    .global-settings-label {
        font: 600 1.2em Lato;
    }

    .coins-package-designer-settings-content {
        display: flex;
        gap: 10px;

        .coins-package-designer-preview-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;

            .coins-package-designer-preview-label-container {
                display: flex;
                align-items: center;
                gap: 5px;

                .coins-package-designer-preview-label {
                    font: 500 1em Lato;
                }

                .coins-package-designer-upload-button {}
            }

            .coins-package-designer-preview-image-container {
                width: 150px;
                min-height: 100px;
                border: 1px solid;

                .coins-package-designer-preview {
                    width: 100%;
                    display: block;
                }
            }
        }

        .form-fields-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .label-label {
                font: 600 1.1em Lato;
            }

            .styling-label {
                font: 600 1.1em Lato;
            }

            .label-form-fields-container {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
            }

            .styling-form-fields-container {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
            }
        }
    }
}