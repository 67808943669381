@import "../../../../../global-style.scss";

.livestream-chat-container {
    padding: 0px 15px 0px 0;
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.follow-label {
    margin-bottom: 5px;
    margin-left: 15px;
    font: 700 1em Lato;
}

.follow-button-container {
    width: fit-content;
    margin-bottom: 10px;
    margin-left: 15px;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 25px;
    cursor: pointer;
    background-color: #FFE18C;

    .following-loading-spinner {
        color: black;
    }

    .text {
        font: 600 0.9em Lato;
    }

    .livestreamer-profile-photo-container {

        .livestreamer-profile-photo {
            height: 30px;
            width: 30px;
        }
    }
}

.tip-container {
    width: 90%;
    padding: 10px 10px 10px 30px;
    border-radius: 0 30px 30px 0;
    background-color: rgba(68, 118, 247, 0.59);

    .tip-label {
        font: 800 0.9em Lato;
        color: white;
    }

    .tip-description-container {
        font: 800 0.9em Lato;
        color: white;

        .diamond-icon {
            height: 13px;
        }

        .highlight-yellow {
            color: #FFDF2B;
        }
    }
}

// Utility CSS
.default-height {
    height: 25vh;
}

.private-call-height {
    height: 35vh;
}

.livestream-height {
    height: calc(100% - 116px - 42px);
}

.livestream-height-tablet {
    height: calc(100% - 116px - 20px - 42px);
}

.yellow {
    color: #FFE18C;
}

.shade-away {
    -webkit-mask-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
}

// Responsive Design
@media (min-width: 900px) {
    // #interactable-overlay-chat-subcomponent {
    //     // height: calc(100% - 91px);
    //     height: calc(100% - 116px - 20px - 42px);
    // }
}

@media (min-width: 1305px) {
    #interactable-overlay-chat-subcomponent {
        // height: calc(100% - 116px - 20px - 42px);
    }
}

@media (max-width: 900px) {
    #interactable-overlay-chat-subcomponent {
        // height: 35vh;
        // -webkit-mask-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
    }
}