#livestream-page {
    height: 100%;
    position: relative;

    .video-player-container {
        height: 100%;
        // width: 568px;
        position: relative;
        // padding-bottom: 56.25%;

        .tablet-desktop-view-container {
            height: calc(100% - 10px);
            width: 100%;
            margin-top: 10px;
            position: absolute;
            top: 0;

            .livestream-desktop-video-overlay-container {
                padding: 20px;
            }

            .quick-gift-container {
                width: 100%;
                position: absolute;
                bottom: 30px;
            }
        }
    }

    .mobile-view {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
    }

    .tablet-view {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    .desktop-view {
        height: 100%;
        position: relative;
    }

    .livestream-swipeable-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 20;

        .react-swipeable-view-container {
            height: 100%;
            position: relative;

            .blank-container {
                padding: 35px 20px;
                display: flex;
                justify-content: flex-end;

                .back-button-container {
                    height: 36px;
                    width: 36px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    .back-button {
                        height: 12px;
                    }
                }
            }

            .stripped-down-container {
                height: 100%;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0) 100%);

                .stripped-down-padding-container {
                    height: calc(100% - 10px - 62px - 60px);
                    padding: 10px 10px 0 10px;
                    display: flex;
                    justify-content: flex-end;
                }

                .gifts-section-container {
                    width: 100%;
                }
            }

            .interactable-container {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }
}

// Responsive Design
@media (min-width: 900px) {
    #livestream-page {
        display: flex;
        justify-content: center;

        .video-player-container {
            // width: 50%;
        }
    }
}

@media (min-width: 1305px) {
    #livestream-page {
        justify-content: center;

        .video-player-container {
            // width: calc(100% / 3);
        }
    }
}

@media (max-width: 900px) {
    #livestream-page {
        display: block;

        .video-player-container {
            width: 100%;
        }
    }
}