#gift-alert-element-subcomponent {
    max-width: 90%;
    margin: 0 0 10px 5%;
    padding-left: 10px;
    display: flex;
    border-radius: 50px;
    -webkit-animation: slideInAndOut 3s forwards;
    -moz-animation: slideInAndOut 3s forwards;
    animation: slideInAndOut 3s ease-in-out;

    .profile-picture-container {
        width: 45px;
        display: flex;
        align-items: center;

        .profile-picture {
            height: 30px;
            width: 30px;
        }
    }

    .chat-info-container {
        width: fit-content;
        max-width: calc(100% - 45px - 50px);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .user-info-container {
            display: flex;
            align-items: center;

            .username {
                font: 700 1em Lato;
                color: #FFE18C;
            }

            .levelling-badge-container {
                padding-left: 5px;
                display: flex;

                .levelling-badge {
                    height: 13px;
                }
            }

            .sent-text {
                padding-left: 5px;
                font: 600 0.9em Lato;
                color: #FFE18C;
            }
        }

        .gift-name {
            font: 600 0.9em Lato;
            color: #FFE18C;
        }

        .gift-diamond-count {
            display: flex;
            gap: 2px;
            font: 600 0.9em Lato;
            color: #00ff75;

            .diamond-icon-container {
                display: flex;
                align-items: center;

                .diamond-icon {
                    height: 13px;
                }
            }
        }
    }

    .gift-icon-container {
        width: 50px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .gift-icon {
            height: 50px;
        }
    }
}