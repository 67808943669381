#co-anchor-card-page {
    // height: 100%;
    width: calc(100% - 290px);

    .max-width-container {
        height: 100%;
        width: 100%;

        .user-card-padding-container {
            height: calc(100% - 70px);
            width: calc(100% - 80px);
            padding: 30px 40px 40px 40px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            overflow: auto;

            .user-card-border-title {
                font: 600 1.5em Lato;
            }

            .infinite-scroller-container {
                padding-bottom: 10px;
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
                gap: 10px;

                .list-container {
                    position: relative;

                    .add-background-image-container {
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        z-index: 30;
                    }
                }

                .add-card-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    .add-user-card {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }

                    .add-gaming-card {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}