@import "../../../../../global-style.scss";

#king-of-the-month-container {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .king-of-the-month-label {
        font: 600 1.5em lato;
    }

    .king-of-the-month-preview-container {
        width: 100%;
        display: flex;
        justify-content: center;

        .king-of-the-month-preview {
            height: 140px;
            width: 250px;
            position: relative;
            border: 1px solid black;

            .king-of-the-month-width {
                position: absolute;
                top: -20px;
                left: 50%;
                transform: translateX(-50%);
            }

            .king-of-the-month-height {
                position: absolute;
                left: -45px;
                top: 50%;
                transform: rotate(-90deg) translateY(50%);
            }
        }
    }

    .upload-king-of-the-month-container {
        text-align: center;
    }

    .show-center-line-button {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        cursor: pointer;
        font: 500 1em Lato;
        color: white;
        background-color: $sugarbook-main-color;
    }

    .king-of-the-month-text {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .username-form-field-container {
            width: 100%;

            .username-form-field {
                width: 100%;
            }
        }
    }

    .animation-duration-container {

        .animation-duration-form-field-container {
            width: 100%;

            .animation-duration-form-field {
                width: 100%;
            }
        }
    }

    .test-animation-button {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        cursor: pointer;
        font: 500 1em Lato;
        color: white;
        background-color: $sugarbook-main-color;
        transition: all 0.5s;
    }

    .disabled-button {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        cursor: not-allowed;
        font: 500 1em Lato;
        color: $sugarbook-grey-text-color;
        background-color: $mui-disabled-button-background-color;
        transition: all 0.5s
    }
}