.livestream-live-leaderboard-profile-border {
    width: fit-content;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;
    background-color: white;

    .livestream-live-leaderboard-label {
        font: 600 1.2em Lato;
    }

    .mobile-desktop-view-label {
        font: 500 1em Lato;
    }

    .mobile-view {
        width: calc(390px - 40px);
        padding: 20px;
        border: 1px solid lightgrey;
        background-color: white;
    }
}