.notification-list-profile-border {
    width: fit-content;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;
    background-color: white;

    .notification-list-label {
        font: 600 1.2em Lato;
    }

    .mobile-desktop-view-label {
        font: 500 1em Lato;
    }

    .mobile-view {
        width: 390px;
        border: 1px solid lightgrey;
        background-color: white;
    }
}