@import "../../../../global-style.scss";

#add-user-dialog {
    width: 310px;

    .padding-container {
        padding: 20px;

        .email-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            .email-form-field {
                width: 100%;
            }
        }

        .password-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 20px;
            margin-left: auto;
            margin-right: auto;

            .password-form-field {
                width: 100%;

                .show-password,
                .hide-password {
                    color: $mui-icon-default-color;
                }
            }
        }

        .login-button {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $sugarbook-main-color;
            border-radius: 25px;
            cursor: pointer;
            text-transform: none;
            font: 500 1.1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
            transition: all 0.5s;

            .button-spinner {
                color: white;
            }
        }
    }
}