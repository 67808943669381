.conversation-page-profile-border {
    width: fit-content;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;
    background-color: white;

    .conversation-label {
        font: 600 1.2em Lato;
    }

    .mobile-desktop-view-label {
        font: 500 1em Lato;
    }

    .sample-view {
        height: 49px;
        width: 390px;
        padding: 10px 0;
        display: flex;
        justify-content: space-around;
        border-bottom: 1px solid lightgrey;
        border: 1px solid lightgrey;
        background-color: white;

        .space-around {
            width: 100%;
            max-width: 1020px;
            padding: 0 12px;

            .mobile-view {
                height: 100%;
                display: flex;
                justify-content: space-between;
            }
        }
    }
}