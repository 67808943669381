#profile-border-page {
    width: calc(100% - 290px);
    // flex-grow: 1;

    .max-width-container {
        height: 100%;
        width: 100%;

        .profile-badge-padding-container {
            height: calc(100% - 70px);
            width: calc(100% - 80px);
            padding: 30px 40px 40px 40px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            overflow: auto;

            .profile-border-title {
                font: 600 1.5em Lato;
            }

            .profile-border-content {
                display: flex;
                flex-wrap: wrap;
                gap: 30px;
            }
        }
    }
}