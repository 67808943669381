#chat-section-gift-element-subcomponent {
    width: fit-content;
    max-width: 90%;
    padding: 5px 0 5px 15px;
    display: flex;
    border-radius: 0 25px 25px 0;

    .profile-picture-container {
        width: 45px;

        .profile-picture {
            height: 30px;
            width: 30px;
        }
    }

    .chat-info-container {
        width: fit-content;
        max-width: calc(100% - 45px - 50px);
        display: flex;
        flex-direction: column;
        gap: 5px;

        .user-info-container {
            display: flex;
            align-items: center;
            gap: 7px;

            .livestreaming-achievement-badge-container {
                display: flex;
                align-items: center;
                gap: 3px;

                .livestreaming-achievement-badge {
                    height: 13px;
                }
            }

            .username {
                font: 700 0.9em Lato;
            }

            .levelling-badge-container {
                display: flex;

                .levelling-badge {
                    height: 13px;
                }
            }

            .sent-text {
                font: 600 0.9em Lato;
            }
        }

        .gift-name {
            font: 600 0.9em Lato;
        }

        .gift-diamond-count {
            display: flex;
            gap: 2px;
            font: 600 0.9em Lato;
            color: #00ff75;

            .diamond-icon-container {
                display: flex;
                align-items: center;

                .diamond-icon {
                    height: 13px;
                }
            }
        }
    }

    .gift-icon-container {
        width: 50px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .gift-icon {
            width: 100%;
            max-height: 100%;
        }
    }
}

// Utility CSS
.added-background-color {
    background-color: rgba(13, 113, 65, 0.59);
}

.yellow {
    color: #FFE18C;
}