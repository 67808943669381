@import "../../../../global-style.scss";

#user-mini-profile-dialog {
    width: 300px;

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .user-profile-photo {
            height: 100px;
            width: 100px;
        }

        .user-data-container {
            width: 100%;
            padding: 20px 0;
            display: flex;

            .user-data-label-container {
                width: 90px;

                .user-data-label {
                    height: 25px;
                }
            }

            .user-data-output-container {
                width: calc(100% - 90px);

                .user-data-output {
                    height: 25px;
                }
            }
        }

        .remove-user-button {
            height: 40px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $sugarbook-main-color;
            border-radius: 25px;
            cursor: pointer;
            text-transform: none;
            font: 500 1.1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
            transition: all 0.5s;

            .button-spinner {
                color: white;
            }
        }
    }
}