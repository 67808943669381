#coins-list-shared-subcomponent {
    // width: calc(100% - 1px);
    padding: 10px 0 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 9px;

    .coins-container {
        height: 100px;
        // width: 100px;
        width: 30%;
        min-width: 106px;
        max-width: 150px;
        position: relative;
        padding-bottom: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        border: 2px solid #eee;
        border-radius: 25px;
        cursor: pointer;
        background-color: #eee;

        .first-row {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .limited-time-badge {

                .limited-time-image {
                    width: 40px;
                }
            }

            .savings-container {
                position: relative;
                left: 10px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                flex-wrap: wrap-reverse;

                .discount-text {
                    height: 10px;
                    padding: 3px;
                    border-radius: 25px;
                    font: 400 0.5em Lato;
                    background: linear-gradient(236.45deg, #AAF49C -10.68%, #34C52E 109.24%);
                }

                .note-1 {
                    height: 15px;
                    padding: 3px;
                    display: flex;
                    align-items: center;
                    border-radius: 25px;
                    font: 600 0.7em Lato;
                    background: radial-gradient(105.37% 1268.76% at 105.37% 133.33%, rgba(255, 55, 103, 0.98) 5.45%, #F5BB19 100%);
                    color: white;
                }
            }


        }

        .second-row {
            display: flex;

            .coin-image-container {
                display: flex;

                .coin-image {
                    width: 20px;
                }
            }

            .quantity-label {
                padding-left: 5px;
                display: flex;
                align-items: center;
                font: 500 0.9em Lato;
            }
        }

        .third-row {

            .discounted-price-label {
                color: rgb(4, 209, 0);
            }
        }

        .fourth-row {

            .original-price-label {
                text-decoration: line-through;
                font: 500 0.6em Lato;
                color: rgb(255, 0, 0);
            }
        }

        .price-label {
            font: 600 0.8em Lato;
            color: rgb(113, 13, 13);
        }

        .most-popular {
            position: absolute;
            bottom: -10px;
            padding: 3px 8px;
            border-radius: 25px;
            text-transform: uppercase;
            font: 600 0.5em Lato;
            background: radial-gradient(105.37% 1268.76% at 105.37% 133.33%, rgba(255, 55, 103, .98) 5.45%, #f5bb19 100%);
            color: white;
        }

        .event-based {
            position: absolute;
            bottom: -10px;
            padding: 3px 8px;
            border-radius: 25px;
            text-transform: uppercase;
            font: 600 0.5em Lato;
            background: linear-gradient(236.45deg, #54fd34 -10.68%, #34c52e 109.24%);
            color: white;
        }
    }

    .selected-coin {
        border: 2px solid rgb(255, 226, 146);
    }
}