@import "../../../../../global-style.scss";

#test-tools-subcomponent {
    position: relative;
    display: flex;
    justify-content: flex-end;

    .livestream-test-icon-container {
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        cursor: pointer;
        background-color: $sugarbook-main-color;

        .livestream-test-icon {
            border-radius: 50px;
            font-size: 30px;
            color: white;
        }
    }
}