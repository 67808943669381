@import "../../../global-style.scss";

#drawer-floating-action-button {
    left: 20px;
    top: 20px;
    position: absolute;

    .open-drawer-button {
        color: $sugarbook-main-color;
    }
}