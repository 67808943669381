#livestream-slide-in-out-alert-overlay-subcomponent {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;

    .padding-container {
        height: calc(70% - 300px);
        padding-top: 300px;
    }
}

// Utility CSS
.gift-element-gradient-1 {
    background: linear-gradient(90deg, #FF0505 -2.66%, rgba(155, 6, 6, 0) 75%);
}

.gift-element-gradient-2 {
    background: linear-gradient(90deg, #05FF00 -2.62%, rgba(24, 73, 2, 0) 75%);
}

.gift-element-gradient-3 {
    background: linear-gradient(90deg, rgba(0, 196, 184, 1) 13.72%, rgba(0, 97, 187, 0) 75%);
}

.gift-element-gradient-4 {
    background: linear-gradient(90deg, #DB00FF -2.5%, rgba(13, 9, 255, 0) 75%);
}

.gift-element-gradient-5 {
    background: linear-gradient(90deg, rgba(196, 152, 0, 1) 13.75%, rgba(255, 28, 28, 0) 75%);
}

.gift-element-gradient-6 {
    background: linear-gradient(90deg, #EC0991 -2.66%, rgba(255, 0, 0, 0) 75%);
}

.gift-element-gradient-7 {
    background: linear-gradient(90deg, #2155A8 -2.6%, rgba(235, 197, 0, 0) 75%);
}

.gift-element-gradient-8 {
    background: linear-gradient(90deg, #FF9394 13.73%, rgba(187, 168, 0, 0) 75%);
}

.gift-element-gradient-9 {
    background: linear-gradient(90deg, #D9004E -2.5%, rgba(194, 0, 12, 0) 75%);
}

.gift-element-gradient-10 {
    background: linear-gradient(90deg, rgba(0, 196, 149, 1) 13.75%, rgba(237, 255, 28, 0) 75%);
}