#livestream-profile-view-dialog-viewer-info-subcomponent {
    overflow-y: auto;
    background-color: white;

    .tab-container {
        height: 100%;

        .swipeable-container {
            height: calc(100% - 48px);

            .react-swipeable-view-container {
                height: 100%;
            }

            .tab-panel {
                height: 100%;

                .tab-box-container {
                    height: 100%;
                }
            }
        }
    }
}

// Utility CSS
.screen-height {
    height: 55vh;
    // min-height: 500px;
    // max-height: 600px;
}

.fix-height {
    height: calc(100% - 161px - 42px);
}