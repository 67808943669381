#king-of-the-month-entrance-container {
    height: 140px;
    width: 250px;
    position: relative;

    .king-of-the-month-entrance-gif-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;

        .king-of-the-month-entrance-asset {
            width: 100%;
        }
    }

    .king-of-the-month-entrance-description-container {
        height: 100%;
        position: relative;
        z-index: 20;
        display: flex;
        justify-content: center;
        align-items: center;
        // font: 600 1em Lato;
        color: black;

        .king-of-the-month-entrance-description {
            max-width: 140px;
            display: flex;
            gap: 5px;

            .profile-photo-container {
                display: flex;
                align-items: center;

                .king-of-the-month-profile-photo {
                    height: 35px;
                    width: 35px;
                }
            }

            .king-of-the-month-description-container {
                width: calc(140px - 40px);

                .king-of-the-month-top-description-container {

                    .king-of-the-month-username {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font: 600 1em Lato;
                    }

                    .king-of-the-month-levelling-badge {}
                }

                .king-of-the-month-bottom-description-container {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font: 500 0.9em Lato;
                }
            }
        }
    }

    .king-of-the-month-entrance-center-line-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 20;
        display: flex;
        justify-content: center;
        align-items: center;

        .kotm-center-line {
            width: 100%;
            background-color: red;
        }
    }
}