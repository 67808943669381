.user-content-container {
    max-width: 190px;
    padding: 3px 8px;
    display: flex;
    border-radius: 50px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(51, 51, 51, 0.4);

    .profile-picture-container {
        width: 45px;
        position: relative;
        display: flex;

        .profile-picture {
            height: 35px;
            width: 35px;
            cursor: pointer;
        }

        .levelling-badge-container {
            position: absolute;
            top: 19px;
            left: 2px;

            .levelling-badge {
                height: 10px;
            }
        }
    }

    .user-details-container {
        width: calc(100% - 45px - 30px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        .username-container {
            display: flex;

            .achievement-container {
                padding-right: 3px;
                display: flex;
                align-items: center;
                gap: 3px;

                .livestreamer-badge {
                    height: 13px;
                }
            }

            .username {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font: 500 1em Lato;
                color: white;
            }
        }

        .livestream-stats-container {
            width: 80%;
            max-width: 130px;
            display: flex;
            justify-content: space-between;
            // flex-wrap: wrap;
            // gap: 20px;

            .viewer-count-container {
                display: flex;
                align-items: center;
                gap: 2px;

                .viewer-count-icon-container {
                    display: flex;
                    align-items: center;

                    .viewer-count-icon {
                        height: 10px;
                    }
                }

                .viewer-count {
                    font: 500 0.7em Lato;
                    color: white;
                }
            }
        }

        .diamond-count-container {
            display: flex;
            align-items: center;
            gap: 2px;

            .diamond-icon-container {
                display: flex;
                align-items: center;

                .diamond-icon {
                    height: 8px;
                }
            }

            .diamond-count {
                font: 500 0.7em Lato;
                color: white;
            }
        }
    }

    .livestreamer-follow-button-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .follow-button {
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            background-color: #FADC89;
        }
    }
}