@import "../../../../../../global-style.scss";

#notification-live-element-subcomponent {
    height: 65px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .avatar-container {
        width: 55px;

        .profile-photo {
            height: 45px;
            width: 45px;
        }
    }

    .live-body-container {
        width: calc(100% - 55px - 95px);

        .username {
            font: 600 1em Lato;
        }

        .premium-mini-badge-container {
            padding-right: 3px;
            position: relative;
            top: 1px;

            .premium-mini-badge {
                height: 15px;
            }
        }

        .notification-verified-profile-badge-container {
            padding-right: 3px;
            position: relative;
            top: 2px;

            .notification-verified-profile-badge {
                height: 15px;
            }
        }

        .timestamp {
            color: #979797;
        }

        .red-dot {
            height: 9px;
            width: 9px;
            margin-left: 5px;
            display: inline-block;
            border-radius: 50px;
            background-color: red;
        }
    }

    .button-container {
        width: 95px;

        .watch-now-button {
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            cursor: pointer;
            font: 500 0.9em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}

// Utility CSS
.notification-unseen {
    background-color: #ed1c2426;
}