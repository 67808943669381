#floating-side-navbar {

    .MuiDrawer-paper {
        height: calc(100% - 40px);
        margin: 20px;
        border: none;
        border-radius: 20px;
    }

    .brand-logo {
        width: 100%;
        text-align: center;
        font: 600 1.1em Lato;
    }

    .navbar-padding-container {
        height: 100%;
        padding: 20px 25px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        background-color: white;

        .user-list-container {
            display: flex;
            flex-direction: column;
            gap: 3px;

            .user-label {
                font: 600 1em Lato;
            }

            .user-list {
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                .user-icon {
                    cursor: pointer;
                }
            }
        }

        .navigation-button-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .navbar-sub-label {
                text-transform: uppercase;
                font: 600 1em Lato;
            }

            .dashboard-button-container {
                height: 50px;
                padding-left: 10px;
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                .dashboard-icon-container {
                    height: 35px;
                    width: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    background-color: white;
                    transition: all 0.3s;

                    .dashboard-icon {
                        color: #710d0d;
                        transition: all 0.3s;
                    }
                }

                .dashboard-label {
                    font: 600 1em Lato;
                    color: #A0AEC0;
                    transition: all 0.3s;
                }
            }

            .livestream-button-container {
                height: 50px;
                padding-left: 10px;
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                .livestream-icon-container {
                    height: 35px;
                    width: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    background-color: white;
                    transition: all 0.3s;

                    .livestream-icon {
                        color: #710d0d;
                        transition: all 0.3s;
                    }
                }

                .livestream-label {
                    font: 600 1em Lato;
                    color: #A0AEC0;
                    transition: all 0.3s;
                }
            }

            .selected-button {
                border-radius: 10px;
                // background: #e0e0e0;
                // box-shadow: 25px 25px 51px #bebebe,
                //     -25px -25px 51px #ffffff;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 11px;
                background-color: white;

                .dashboard-icon-container,
                .livestream-icon-container {
                    background-color: #710d0d;

                    .dashboard-icon,
                    .livestream-icon {
                        color: white;
                    }
                }

                .dashboard-label,
                .livestream-label {
                    color: black;
                }
            }
        }
    }
}

#side-navbar {
    width: 250px;
    margin: 20px;
    border-radius: 20px;
    background-color: white;

    .brand-logo-container {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        .brand-logo {
            width: 100%;
            text-align: center;
            font: 600 1.1em Lato;
        }
    }

    .navbar-padding-container {
        padding: 0 20px 20px 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .user-list-container {
            display: flex;
            flex-direction: column;
            gap: 3px;

            .user-label {
                font: 600 1em Lato;
            }

            .user-list {
                display: flex;
                flex-wrap: wrap;
                gap: 5px;

                .user-icon {
                    cursor: pointer;
                }
            }
        }

        .navigation-button-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .navbar-sub-label {
                text-transform: uppercase;
                font: 600 1em Lato;
            }

            .dashboard-button-container {
                height: 50px;
                padding-left: 10px;
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                .dashboard-icon-container {
                    height: 35px;
                    width: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    background-color: white;
                    transition: all 0.3s;

                    .dashboard-icon {
                        color: #710d0d;
                        transition: all 0.3s;
                    }
                }

                .dashboard-label {
                    font: 600 1em Lato;
                    color: #A0AEC0;
                    transition: all 0.3s;
                }
            }

            .livestream-button-container {
                height: 50px;
                padding-left: 10px;
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                .livestream-icon-container {
                    height: 35px;
                    width: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    background-color: white;
                    transition: all 0.3s;

                    .livestream-icon {
                        color: #710d0d;
                        transition: all 0.3s;
                    }
                }

                .livestream-label {
                    font: 600 1em Lato;
                    color: #A0AEC0;
                    transition: all 0.3s;
                }
            }

            .selected-button {
                border-radius: 10px;
                // background: #e0e0e0;
                // box-shadow: 25px 25px 51px #bebebe,
                //     -25px -25px 51px #ffffff;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 11px;
                background-color: white;

                .dashboard-icon-container,
                .livestream-icon-container {
                    background-color: #710d0d;

                    .dashboard-icon,
                    .livestream-icon {
                        color: white;
                    }
                }

                .dashboard-label,
                .livestream-label {
                    color: black;
                }
            }
        }
    }
}