@import "../../../../../global-style.scss";

#conversation-editor-test-element {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .conversation-editor-preview-container {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .preview-label {
            font: 600 1.2em Lato;
        }

        .conversation-editor-preview {

            .chatting-preview-container,
            .gifting-preview-container,
            .joining-preview-container,
            .following-preview-container,
            .emoji-preview-container {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }

            .sub-preview-label {
                margin-left: 5px;
                font: 500 1em Lato;
            }
        }
    }

    .form-selection-container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .conversation-form-field-container {
            width: 100%;

            .conversation-form-field {
                width: 100%;
            }
        }

        .username-form-field-container {
            width: 100%;

            .username-form-field {
                width: 100%;
            }
        }

        .livestreamer-username-form-field-container {
            width: 100%;

            .livestreamer-username-form-field {
                width: 100%;
            }
        }

        .font-size-form-field-container {
            width: 100%;

            .font-size-form-field {
                width: 100%;
            }
        }

        .font-weight-form-field-container {
            width: 100%;

            .font-weight-form-field {
                width: 100%;
            }
        }

        .border-settings-container {
            padding: 10px;
            display: flex;
            gap: 10px;
            border: 1px solid;
            border-radius: 10px;

            .border-style-form-field-container {
                width: calc((100% - 25%) / 2);

                .border-style-form-field {
                    width: 100%;
                }
            }

            .border-width-form-field-container {
                width: 25%;

                .border-width-form-field {
                    width: 100%;
                }
            }

            .border-color-form-field-container {
                width: calc((100% - 25%) / 2);

                .border-color-form-field {
                    width: 100%;
                }
            }
        }

        .border-radius-form-field-container {
            width: 100%;

            .border-radius-form-field {
                width: 100%;
            }
        }

        .text-stroke-settings-container {
            padding: 10px;
            display: flex;
            gap: 10px;
            border: 1px solid;
            border-radius: 10px;

            .text-stroke-width-form-field-container {
                width: 50%;

                .text-stroke-width-form-field {
                    width: 100%;
                }
            }

            .text-stroke-color-form-field-container {
                width: 50%;

                .text-stroke-color-form-field {
                    width: 100%;
                }
            }
        }

        .text-shadow-settings-container {
            padding: 10px;
            border: 1px solid;
            border-radius: 10px;

            .text-shadow-color-form-field-container {
                width: 100%;

                .text-shadow-color-form-field {
                    width: 100%;
                }
            }
        }

        .text-color-form-field-container {
            width: 100%;

            .text-color-form-field {
                width: 100%;
            }
        }
    }

    .simulate-conversation-button {
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        cursor: pointer;
        font: 500 1em Lato;
        color: white;
        background-color: $sugarbook-main-color;
        transition: all 0.5s;
    }
}