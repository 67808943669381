@import "../../../../global-style.scss";

#product-purchase-subcomponent {
    height: calc(100% - 42px);
    background-color: white;

    .padding-container {
        height: calc(100% - 40px);
        padding: 20px;

        .header-container {
            display: flex;
            align-items: center;

            .left-container {
                flex: 1;
            }

            .middle-container {
                flex: 4;
                text-align: center;
                font: 600 1.5em Lato;
            }

            .right-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex: 1;

                .close-icon-container {
                    display: flex;
                    align-items: center;

                    .close-icon {
                        cursor: pointer;
                    }
                }
            }
        }

        .tab-container {
            height: calc(100% - 29px - 26px);

            .swipeable-container {
                height: calc(100% - 48px);
            }
        }

        .bottom-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .balance-container {
                height: 20px;
                padding: 3px 10px 3px 7px;
                display: flex;
                align-items: center;
                gap: 4px;
                border-radius: 25px;
                cursor: pointer;
                background-color: #e4e5e7;

                .add-icon-container {
                    display: flex;

                    .add-icon {
                        height: 17px;
                    }
                }

                .balance-label {
                    font: 600 0.8em Lato;
                }

                .coin-icon-container {

                    .coin-icon {
                        height: 15px;
                        ;
                    }
                }

                .coin-value {
                    font: 600 0.8em Lato;
                }
            }

            .send-button {
                height: 35px;
                width: 90px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }
        }

        // Utility CSS
        .headerless {
            height: calc(100% - 26px);
        }
    }
}